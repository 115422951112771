/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'caret-right-square-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 2a2 2 0 012-2h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2zm5.5 10a.5.5 0 00.832.374l4.5-4a.5.5 0 000-.748l-4.5-4A.5.5 0 005.5 4z"/>',
    },
});
